@import "../../../../../common/ui/src/lib/scss/variables/colors";

$block-codes-max-width: 1000px;
$block-codes-max-height: 550px;
$block-codes-height-padding: 30px;
$block-codes-list-padding: 15px;

.block-codes-wrapper {
  background-color: white;
  padding: $block-codes-height-padding 40px;
  min-height: var(--content-height);

  .section-description {
    max-width: $block-codes-max-width;
  }
}

.block-codes-editing-submit-button {
  min-width: 150px;
  margin-left: 16px;
}

.block-codes-select {
  min-width: 250px;
  :global {
    .ant-select-selector {
      height: 33px !important;
    }
  }
}

.block-codes-default-submit-button:global(.ant-btn-default) {
  min-width: 150px;
  background-color: white;
  color: $main-red-1;
  &:disabled {
    color: $main-black-8 !important;
  }
}

.sort-link-container {
  margin-bottom: 24px;
  min-width: 200px;
}

.block-codes-drawer-component {
  :global {
    .ant-drawer-header {
      justify-content: space-between;
      flex-wrap: wrap;

      .ant-drawer-header-title {
        white-space: nowrap;
        flex: initial;
      }

      .ant-drawer-extra {
        display: flex;
        flex-wrap: wrap;
        flex: initial;
      }
    }
  }
}
