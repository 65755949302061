@import "../../scss/variables/colors";

.ni-truncated-label {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ni-form-label-container {
  :global {
    .anticon-file-excel {
      svg {
        margin-left: 0 !important;
      }
    }

    .ant-tooltip-inner {
      color: aqua !important;
    }

    .ant-space-item {
      display: flex;
      justify-content: center;
    }
  }

  .form-item-code-icon {
    color: $icon-background-gray;
  }
}
