@import "../../../../../common/ui/src/lib/scss/variables/colors";

.additional-services-details-container {
  max-width: 700px;
  &.additional-services-details-card-section {
    border: 1px solid $main-black-9;
    border-radius: 8px;

    .additional-services-details-card-header {
      background: $main-black-13;
      border-bottom: 1px solid $main-black-9;
      border-radius: 8px 8px 0 0;
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      padding: 20px 16px;
    }

    .additional-services-details-card-body {
      padding: 20px 16px;
    }
  }
  .additional-services-details-form-item {
    align-items: center;
    display: flex;

    :global {
      .ant-form-item {
        margin-bottom: 0;
      }

      .ant-form-item-control-input-content {
        button {
          margin-right: 18px;
        }
      }
    }
  }
}
